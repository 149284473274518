body {
  margin: 0;
  --font-family-sans-serif: -'Open Sans', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --mui-palette-primary-main: #1976d2;
  --mui-palette-primary-light: #42a5f5;
  --mui-palette-primary-dark: #1565c0;
  --mui-palette-primary-contrastText: #fff;
  /* ...other variables */
}


/* Potrzebne dla generowanie pdfów*/
.invoice-wrapper {
  position: relative;
  background: #fff;
  padding: 40px 35px;
  box-shadow: 0 0 17px 0 rgba(16, 40, 73, 0.09);
}

.flex {
  display: flex;
}

.flex-center{
  align-items: center;
  justify-content: center;
}

.ml-50 {
         margin-left: 40%;
       }
.w-50 {
  width: 50% !important;
}
.w-48 {
  width: 48% !important;
}
.w-75 {
  width: 75% !important;
}
.w-8 {
  width: 8% !important;
}
.fs-20 {
  font-size: 20px !important;
}
.bold {
  font-weight: 600;
}

/*.fs-10 {*/
/*  font-size: 10px;*/
/*}*/
.fs-45 {
  font-size: 40px;
}
.mt-20 {
  margin-top: 20px;
}

.w-100 {
  width: 100% !important;
}

.invoice-right {
  justify-content: right;
}
.invoice-inline {
  display: inline;
}

.center {
    text-align: center;
  }

.invoice-text,
.invoice-textarea,
.input {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  color: #555;
  background: transparent;
  border-radius: 3px;
  outline: 0;
  border: 1px dotted transparent;
}
.invoice-text,
.invoice-textarea,
.input   {
  &:hover,
  &:focus {
    background: #333;
  }}
